function lightbox_open(){var e=document.getElementById("VisaChipCardVideo");window.scrollTo(0,0),document.getElementById("light").style.display="block",document.getElementById("fade").style.display="block",e.play()}function lightbox_close(){var e=document.getElementById("VisaChipCardVideo");document.getElementById("light").style.display="none",document.getElementById("fade").style.display="none",e.pause()}function lightbox_open2(){var e=document.getElementById("VisaChipCardVideo2");window.scrollTo(0,0),document.getElementById("light2").style.display="block",document.getElementById("fade2").style.display="block",e.play()}function lightbox_close2(){var e=document.getElementById("VisaChipCardVideo2");document.getElementById("light2").style.display="none",document.getElementById("fade2").style.display="none",e.pause()}function lightbox_open3(){var e=document.getElementById("VisaChipCardVideo3");window.scrollTo(0,0),document.getElementById("light3").style.display="block",document.getElementById("fade3").style.display="block",e.play()}function lightbox_close3(){var e=document.getElementById("VisaChipCardVideo3");document.getElementById("light3").style.display="none",document.getElementById("fade3").style.display="none",e.pause()}window.document.onkeydown=function(e){e||(e=event),27==e.keyCode&&lightbox_close()};$(document).ready(function(){for(var e=document.querySelectorAll(".youtube"),t=0;t<e.length;t++){var d="https://img.youtube.com/vi/"+e[t].dataset.embed+"/sddefault.jpg",r=new Image;r.src=d,r.addEventListener("load",void e[t].appendChild(r)),e[t].addEventListener("click",function(){var e=document.createElement("iframe");e.setAttribute("frameborder","0"),e.setAttribute("allowfullscreen",""),e.setAttribute("src","https://www.youtube.com/embed/"+this.dataset.embed+"?rel=0&showinfo=0&autoplay=1"),this.innerHTML="",this.appendChild(e)})}});

$('body').on('click touchstart', function () {
    if(window.location.pathname=='/page/landing'){
        const videoElement=$('.player_container').find('video').get(0);
        if (videoElement.playing) {
            // video is already playing so do nothing
            console.log('playing');
        }
        else {
            console.log('not playing');
            // video is not playing
            // so play video now
            videoElement.play();
    
        }
    }
});