// JavaScript Document
$(document).ready(function() {
  $(".accordion01 h3").eq(40).addClass("active");
  $(".accordion01 p").eq(40).show();
  $(".accordion01 h3").click(function() {
    var video = $(".accordion01 h3.active").next().children();
    var src = video.attr("src");
    video.attr("src", "");
    video.attr("src", src);

    $(this).next("p").slideToggle("slow")
      .siblings("p:visible").slideUp("slow");
    $(this).toggleClass("active");
    $(this).siblings("h3").removeClass("active");
  });
});

